/* eslint-disable react/prop-types */
import React from 'react'
import styled from 'styled-components'
import { GatsbyImage } from 'gatsby-plugin-image'
import { renderRichText } from 'gatsby-source-contentful/rich-text'
import { BLOCKS, INLINES } from '@contentful/rich-text-types'
import {
  BlogArticle,
  ArticleAuthor,
  StyledHero,
  StyledHeadline,
} from '../components/blog/StyledBlogArticle'
import SEO from '../components/Seo'

const StyledImage = styled.div`
  max-width: 920px;
  min-height: 460px;
  margin: -120px auto 100px;

  div {
    border-radius: 10px;
    box-shadow: 0 23px 55px rgb(0 0 0 / 34%);
  }
`

const IframeContainer = styled.span`
  padding-bottom: 56.25%;
  position: relative;
  display: block;
  width: 100%;

  > iframe {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }
`

const options = {
  renderNode: {
    [BLOCKS.EMBEDDED_ASSET]: (node) => {
      const { gatsbyImageData } = node.data.target
      if (!gatsbyImageData) {
        // asset is not an image
        return null
      }

      return <GatsbyImage image={node.data.target.gatsbyImageData} />
    },
    [BLOCKS.EMBEDDED_ENTRY]: (node, children) => {
      const entry = node.data.target
      return (
        <IframeContainer>
          <iframe
            title={entry.name}
            src={entry.embedVideoLink}
            allow="accelerometer; encrypted-media; gyroscope; picture-in-picture"
            frameBorder="0"
            allowFullScreen
          />
        </IframeContainer>
      )
    },
    [INLINES.HYPERLINK]: (node) => (
      <a href={node.data.uri} target="_blank" rel="noopener noreferrer">
        {node.content[0].value}
      </a>
    ),
  },
}
function BlogPostTemplate({ pageContext: { data } }) {
  const pageContent = renderRichText(data.body, options)
  const pageExcerpt = renderRichText(data.excerpt)
  return (
    <>
      <SEO
        title={data.metadata?.title || data.title}
        description={
          data.metadata?.description || pageExcerpt[0].props.children[0]
        }
        imageUrl={`https:${data.heroImage.file.url}`}
        url={`https://innential.com/blog/${data.slug}/`}
      />
      <StyledHero>
        <StyledHeadline>{data.title}</StyledHeadline>
        <ArticleAuthor>
          <span>{data.author}</span>
          {/* <span>{post.date}</span> */}
        </ArticleAuthor>
      </StyledHero>
      <StyledImage>
        <GatsbyImage image={data.heroImage?.gatsbyImageData} alt=" " />
      </StyledImage>
      <BlogArticle
        className="blog-post"
        itemScope
        itemType="http://schema.org/Article"
      >
        <section itemProp="articleBody">{pageContent}</section>
      </BlogArticle>
    </>
  )
}

export default BlogPostTemplate
