import styled from 'styled-components'

export const BlogArticle = styled.article`
  max-width: 650px;
  margin: 0 auto 85px;
  padding: 0 15px;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: 900;
    color: #152540;
    margin-bottom: 20px;
  }
  h1 {
    font-size: 48px;
    line-height: 76px;
  }
  h2 {
    font-size: 32px;
    line-height: 52px;
    padding-top: 24px;
    &:first-of-type {
      padding-top: 0px;
    }
  }
  h3 {
    font-size: 24px;
    line-height: 38px;
  }
  h4 {
    font-size: 20px;
    line-height: 32px;
  }
  h5,
  h6 {
    font-size: 18px;
    line-height: 26px;
  }
  p,
  li {
    font-weight: 400;
    font-size: 18px;
    line-height: 32px;
    color: #3b4b66;
    margin-bottom: 20px;
  }
  a {
    text-decoration: underline;
  }
  blockquote {
    border-left: 4px solid #e7ebee;
    padding-left: 10px;
    margin-left: 10px;
  }
`
export const ArticleCategory = styled.div`
  font-size: 16px;
  line-height: 18px;
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;

  a {
    color: #fff;
    text-decoration: none;
    padding-right: 10px;
    text-decoration: underline;
    font-weight: 500;
  }
`

export const ArticleAuthor = styled.div`
  font-size: 12px;
  line-height: 16px;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;

  span:first-child {
    font-weight: 600;
  }
`
export const StyledHero = styled.div`
  background-color: #5a55ab;
  min-height: 60vh;
  width: 100%;
  margin-top: -120px;
  padding-top: 120px;
  padding-bottom: 215px;
  color: #fff;
  text-align: center;
`
export const StyledHeroGuide = styled.div`
  background-color: #5a55ab;
  min-height: 60vh;
  width: 100%;
  margin-top: -120px;
  padding-top: 120px;
  padding-bottom: 215px;
  color: #fff;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

export const StyledHeadline = styled.h1`
  max-width: 830px;
  font-size: 48px;
  line-height: 76px;
  margin: 10px auto 35px;
`
export const DownloadDescription = styled.article`
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: 900;
    color: #152540;
    margin-bottom: 16px;
  }
  h1 {
    font-size: 48px;
    line-height: 76px;
  }
  h2 {
    font-size: 32px;
    line-height: 52px;
    padding-top: 24px;
    &:first-of-type {
      padding-top: 0px;
    }
  }
  h3 {
    font-size: 24px;
    line-height: 38px;
  }
  h4 {
    font-size: 20px;
    line-height: 32px;
  }
  h5,
  h6 {
    font-size: 18px;
    line-height: 26px;
    color: #42526b;
  }
  p,
  li {
    font-weight: 400;
    font-size: 16px;
    line-height: 32px;
    color: #42526b;
    margin-bottom: 8px;
  }
  a {
    text-decoration: underline;
  }
`
export const GuideArticle = styled.article`
  max-width: 632px;
  margin: 0 auto 85px;
  padding: 0;
  @media (max-width: 768px) {
    padding: 0 15px;
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: 900;
    color: #152540;
    margin-bottom: 20px;
  }
  h1 {
    font-size: 48px;
    line-height: 76px;
  }
  h2 {
    font-size: 32px;
    line-height: 52px;
    padding-top: 24px;
    &:first-of-type {
      padding-top: 0px;
    }
  }
  h3 {
    font-size: 24px;
    line-height: 38px;
  }
  h4 {
    font-size: 20px;
    line-height: 32px;
  }
  h5,
  h6 {
    font-size: 18px;
    line-height: 26px;
  }
  p,
  li {
    font-weight: 400;
    font-size: 18px;
    line-height: 32px;
    color: #3b4b66;
    margin-bottom: 20px;
  }
  a {
    text-decoration: underline;
  }
`
